import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import PageTitle from "../PageTitle/PageTitle";
import useEmblaCarousel from 'embla-carousel-react'
import { DotButton, useDotButton } from './DotButton'
import { Widgets } from "@material-ui/icons";

// import { getHomeData } from "../../redux/actions/home-actions";
const useStyles = makeStyles((theme) => ({
    GifT_Gallery: {
        display: "grid",
        "grid-template-columns": "repeat(3, 1fr)",
        "grid-template-rows": "masonry",
        // padding: "40px  0",
        // "grid-template-columns": "auto auto auto",
        gridGap: 10,
        paddingTop: 10

    },
    Gif_Container: {
        marginTop: "15px",
        position: "relative"

    },
}));
const options = {
    align: 'start',
    dragFree: true,
    direction: 'rtl',
    slidesToScroll: 'auto'
}
const GameCart = ({ homeData }) => {
    const { home, loading } = homeData;
    const [data, setData] = useState([])
    const [selected, setSelected] = useState({})
    const [allCats, setAllCats] = useState([])
    const classes = useStyles();
    const [emblaRef, emblaApi] = useEmblaCarousel(options)

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi)

    const flatCat = async (arr) => {
        const childes = arr?.flatMap(item => item?.children)
        return childes
    }
    useEffect(() => {
        if (home) {
            setData(home?.data?.categories)
            flatCat(home?.data?.categories).then(res => setAllCats(res))
        }
    }, [home])
    const allCatsSetter = () => {
        setSelected({ children: allCats, id: 0 })
    }

    return (
        <div id="catgories" className="Game_Cart" style={{ paddingTop: 10 }}>
            <Box component="div" className={classes.Gif_Container}>
                <div style={{ position: 'absolute', left: 0, top: -23, zIndex: 10, paddingRight: 10 }}>
                    <Button endIcon={<Widgets fontSize="large" style={{ marginRight: 10 }} />} onClick={allCatsSetter} style={{ fontSize: 13, color: selected.id === 0 ? "#0A7DCE" : "#383737" }}>همه کارت ها</Button>
                </div>

                <PageTitle title={"دسته بندی ها"} variant="h2" />
                <Box component="div">
                    {loading ? (
                        <Grid style={{ display: "flex", gap: 5 }}>
                            {[1, 2, 3, 4].map((_, i) => (
                                <Grid xs={3} >
                                    <Skeleton style={{ flex: 1 }} key={i} variant="circle" height={95} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (

                        <section className="embla" dir="rtl">
                            <div className="embla__viewport" ref={emblaRef}>
                                <div className="embla__container">
                                    {data?.map((item) => (
                                        <div key={item.id} className="embla__slide">
                                            <a href="#catgories" onClick={() => {
                                                setSelected(item);
                                            }} style={{ display: "inline-block", width: '100%', textAlign: "center" }}>
                                                <img width={"100%"} src={item.photo} alt={item.name} className={"Gif_Hover"} style={item.id === selected.id ? { border: "solid #0A7DCE 3px", borderRadius: "50%" } : { borderRadius: "50%" }} />
                                                <Typography style={item.id === selected.id ? { fontSize: 13, color: "#0A7DCE", paddingTop: 10, fontWeight: "bolder" } : { fontSize: 13, color: "#333", paddingTop: 10 }}>{item.name}</Typography>

                                            </a>
                                        </div>
                                    ))

                                    }
                                </div>
                            </div>
                            <div className="embla__controls">
                                <div className="embla__dots">
                                    {scrollSnaps.length > 1 && scrollSnaps.map((_, index) => (
                                        <DotButton
                                            key={index}
                                            onClick={() => onDotButtonClick(index)}
                                            className={'embla__dot'.concat(
                                                index === selectedIndex ? ' embla__dot--selected' : ''
                                            )}
                                        />
                                    ))}
                                </div>
                            </div>
                        </section>
                    )}
                    <Box className={`${classes.GifT_Gallery}`}>
                        {selected.children &&
                            selected.children.map((item) => <Link
                                key={item.id}
                                style={{ display: 'block' }}
                                to={`/categories/${item.slug}`}
                                className={classes.Gif_Hover}
                            >
                                <img width={"100%"} src={item.photo} alt={item.name} className={"Gif_Hover"} />
                            </Link>)
                        }
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default GameCart;
