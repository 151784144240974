import React, { useEffect, useState } from "react";
import SingleGridCard from "../SingleGridCard/SingleGridCard";

import { Grid } from "@material-ui/core";
import PageTitle from "../PageTitle/PageTitle";
import { useDispatch } from "react-redux";
import { getHomeData } from "../../redux/actions/home-actions";

import Skeleton from "@material-ui/lab/Skeleton";

import { useTranslation } from "react-i18next";

const GiftCart = ({ title, homeData, refScroll }) => {
  const { home, loading } = homeData;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const skel = [0, 1, 2, 3, 4, 5]
  useEffect(() => {
    !home?.data && dispatch(getHomeData());
  }, [dispatch]);

  const Skelt = () =>
    skel.map((_, i) => (
      <Grid item xs={4} key={_} >
        <Skeleton variant="rect" style={{ borderRadius: 15 }} height={180} />
      </Grid>
    ))

  return (
    <div ref={refScroll}>
      {title && <PageTitle title={t("most_viewed")} variant="h2" />}
      <Grid className="Cart_Game_head" container spacing={2}>
        {loading ? (
          <Grid className={"mt-5 mb-5 p-2"} container spacing={2}>
            <Skelt />
          </Grid>
        ) : (
          home?.data?.most_visitors_products.map((item, i) => (
            <SingleGridCard key={i} item={item} />
          ))
        )}
      </Grid>
    </div>
  );
};

export default GiftCart;
